import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { watchTowerInstance } from "../../../utils/api/axiosConfig";
import { formulateTowerError } from "../../../utils/api/formulateTowerError";
import { notifyWithError } from "../error/error";
import { formulateWatchTowerError } from "../../../utils/api/formulateWatchTowerError";

const initialState = {
  orderTrackingInformation: null,
  fetchOrderTrackingInformationStatus: "idle",
};

export const getOrderTrackingInformation = createAsyncThunk(
  "orderTracking/getOrderTrackingInformation",
  async (publicID, thunkAPI) => {
    const response = await watchTowerInstance.get(`sales/track/${publicID}/`);
    if (response.status < 200 || response.status >= 300) {
      thunkAPI.dispatch(
        notifyWithError({
          message: formulateWatchTowerError(response.data),
          type: "error",
        })
      );
      return thunkAPI.rejectWithValue(formulateTowerError(response.data));
    } else {
      return response.data;
    }
  }
);

export const orderTrackingSlice = createSlice({
  name: "orderTracking",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrderTrackingInformation.fulfilled, (state, action) => {
      state.fetchOrderTrackingInformationStatus = "succeeded";
      state.orderTrackingInformation = action.payload;
    });
    builder.addCase(getOrderTrackingInformation.pending, (state, action) => {
      state.fetchOrderTrackingInformationStatus = "loading";
    });
    builder.addCase(getOrderTrackingInformation.rejected, (state, action) => {
      state.fetchOrderTrackingInformationStatus = "failed";
    });
  },
});

// export const {} = orderTrackingSlice.actions;

export default orderTrackingSlice.reducer;
