import { Navigate, Outlet } from "react-router-dom";
import { validateTowerToken } from "../utils/api/Authentication";

const LoginProtected = ({ children }) => {
  const redirectPath = "/home";
  if (validateTowerToken()) {
    return <Navigate to={redirectPath} replace />;
  }
  return children ? children : <Outlet />;
};
export default LoginProtected;
