import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { useSmartlook } from "../api/smartlook";
import { useGoogleAnalytics } from "../api/googleAnalytics";
import {
  validateTowerAndWatchTowerTokens,
  validateTowerToken,
  validateWatchtowerToken,
} from "../api/Authentication";
import { setUserContext } from "../api/sentry";
import { checkBetaUser } from "../../app/features/auth/auth";
import {
  checkClientValidity,
  getWatchTowerToken,
  setUserFirstAndLastName,
} from "../../app/features/wallet/wallet";
import IsEmpty from "../validation/IsEmpty";

/* updates user analytics and tracking attributes if the user is valid. */
const updateUserAttributes = (
  user,
  betaUser,
  userLang,
  setUserAttributes,
  logUserProperties
) => {
  if (!user) return;

  const userAttributes = {
    user_id: user.user_id,
    name: `${user.user.first_name} ${user.user.last_name}`,
    phone: user.user.phone,
    is_staff: user.user.is_staff,
    isBetaUser: betaUser,
    lang: userLang,
  };

  // Google Analytics
  logUserProperties(userAttributes.user_id, userAttributes);

  // Smartlook
  setUserAttributes(userAttributes.user_id, {
    userAttributes,
    language: userLang, // Language should be set separately
  });

  // Sentry
  setUserContext(userAttributes.user_id, {
    phone: userAttributes.phone,
    is_staff: userAttributes.is_staff,
    isBetaUser: userAttributes.isBetaUser,
    lang: userLang,
  });
};

const useAppSetup = () => {
  //SECTION - general
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const betaUser = useSelector((state) => state.authReducer.betaUser);

  //SECTION - hooks
  const { setUserAttributes, alreadySetUserAttributes } = useSmartlook();
  const { logUserProperties, initGA4 } = useGoogleAnalytics();

  //SECTION - useEffect - initialize Google Analytics and set user tracking attributes
  useEffect(() => {
    initGA4();

    if (
      alreadySetUserAttributes ||
      !validateTowerAndWatchTowerTokens() ||
      !IsEmpty(betaUser)
    )
      // return if attributes are already set or user is not valid
      return;

    const user = jwtDecode(localStorage.getItem("watchTowerAccessToken"));
    const userLang = i18n.language;

    // call the shared updateUserAttributes function
    updateUserAttributes(
      user,
      betaUser,
      userLang,
      setUserAttributes,
      logUserProperties
    );
  }, [
    alreadySetUserAttributes,
    betaUser,
    setUserAttributes,
    logUserProperties,
    i18n.language,
    initGA4,
  ]);

  // validate tokens and fetch user details
  useEffect(() => {
    if (validateWatchtowerToken()) {
      dispatch(checkBetaUser());
      dispatch(checkClientValidity());
      dispatch(setUserFirstAndLastName());
    } else if (validateTowerToken()) {
      dispatch(getWatchTowerToken());
    }
  }, [dispatch]);
};

export default useAppSetup;
