import { Typography } from "@mui/material";
import { Stack, useMediaQuery } from "@mui/system";
import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import DownloadAppIcons from "../../common/DownloadAppIcons";
import VATSection from "./VATSection";
import moment from "moment";
import TermsAndPrivacyLinks from "../../common/TermsAndPrivacyLinks";
import NavigationIcon from "./NavigationIcon";
import useGenerateNavigationItems from "./hooks/useGenerateNavigationItems";
import AutobiaLogo from "../../common/AutobiaLogo";

const Footer = () => {
  //SECTION - general
  const { t } = useTranslation();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  //SECTION - hooks
  const navigationItems = useGenerateNavigationItems();

  return (
    <Stack
      style={{
        width: "100%",
        background: "linear-gradient(86.48deg, #FFFFFF 0%, #FCF1E8 100.2%)",
        clipPath: "polygon(100% 0, 100% 100%, 0 100%, 0 16%)",
        position: "relative",
      }}
      paddingTop={"4rem"}
      paddingBottom={"2rem"}
      alignItems={"center"}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="81"
        height="199"
        viewBox="0 0 81 199"
        fill="none"
        style={{
          position: "absolute",
          bottom: isLargeScreen ? "25%" : "45%",
          right: "0",
          zIndex: -1,
        }}
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M541.999 1.17879C549.042 -0.0653602 555.5 5.35186 555.5 12.5034V94.7556C555.5 100.322 551.513 105.09 546.034 106.074L39.0336 197.168C33.0214 198.248 27.4241 194.468 25.9039 188.89L360.219 132.419C365.81 131.475 369.977 126.747 370.21 121.082L372.805 58.2268C373.119 50.6225 366.365 44.6506 358.857 45.8927L25.5468 101.029C26.0093 95.9066 29.8503 91.6563 34.9993 90.7466L541.999 1.17879ZM24.5302 101.198C24.9249 95.5142 29.1421 90.7659 34.8253 89.7619L541.825 0.194037C549.48 -1.1583 556.5 4.72998 556.5 12.5034V94.7556C556.5 100.807 552.166 105.989 546.21 107.059L39.2105 198.152C32.6407 199.333 26.5266 195.174 24.913 189.057L14.8525 190.757C7.33161 192.027 0.54948 186.05 0.864043 178.429L3.50859 114.36C3.74305 108.68 7.93112 103.943 13.5399 103.016L24.5302 101.198Z"
          fill="#FACCA6"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="102"
        height="87"
        viewBox="0 0 102 87"
        fill="none"
        style={{
          position: "absolute",
          bottom: isLargeScreen ? "5%" : "20%",
          left: "0",
          zIndex: -1,
        }}
      >
        <path
          d="M-129.929 86.3461C-137.273 87.6327 -144 81.9817 -144 74.5261L-144 50.4862C-144 44.6448 -139.794 39.652 -134.037 38.6604L87.9628 0.418077C95.2966 -0.845246 102 4.80217 102 12.2439V35.632C102 41.4605 97.8119 46.4462 92.0708 47.452L-129.929 86.3461Z"
          fill="#FDE5D2"
        />
      </svg>
      <Stack
        maxWidth={"1400px"}
        paddingX={{ xs: "0.5rem", md: "2rem", lg: "3rem" }}
        gap={"2rem"}
        justifyContent={"space-between"}
        direction={{ xs: "column", lg: "row" }}
      >
        <Stack
          gap={"1rem"}
          textAlign={{ xs: "center", lg: "start" }}
          alignItems={{ xs: "center", lg: "flex-start" }}
          width={"100%"}
        >
          <AutobiaLogo
            isLarge={isLargeScreen}
            sizes={{
              ar: { sm: "40px", lg: "55px" },
              en: {
                sm: "35px",
                lg: "48px",
              },
            }}
          />

          <Typography color="tertiary.tertiary2">
            {t("footer.description")}
          </Typography>
          <DownloadAppIcons />
        </Stack>

        <Stack
          width={"100%"}
          direction={"column"}
          gap={"1rem"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Stack />
          <Stack direction={"column"} gap={"0.5rem"} alignItems={"center"}>
            <Typography color="tertiary.tertiary2" variant="body2">
              {t("footer.contactUs")}
            </Typography>
            <Stack direction={"row"} gap={"0.5rem"} flexWrap={"wrap"}>
              {navigationItems.map((item, index) => (
                <NavigationIcon icon={item.icon} link={item.link} key={index} />
              ))}
            </Stack>
          </Stack>

          {isLargeScreen && (
            <Typography variant="body1" color="tertiary.tertiary3">
              {t("labels.copyrights", { year: moment().year() })}
            </Typography>
          )}
        </Stack>

        <Stack
          width={"100%"}
          justifyContent={"space-between"}
          gap={"1rem"}
          alignItems={"center"}
        >
          <Stack />
          <VATSection />
          <TermsAndPrivacyLinks color={"inherit"} />
          {!isLargeScreen && (
            <Typography variant="body1" color="tertiary.tertiary3">
              {t("labels.copyrights", { year: moment().year() })}
            </Typography>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Footer;
