import { Typography, Box } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import VATIcon from "../../../assets/images/legal/VATIcon.png";
import VATRegistrationCertificateModal from "./VATRegistrationCertificateModal";

const VATSection = () => {
  //SECTION - general
  const { t } = useTranslation();

  //SECTION - useState
  const [openVATCertificateModal, setOpenVATCertificateModal] = useState(false);
  return (
    <>
      <VATRegistrationCertificateModal
        open={openVATCertificateModal}
        onClose={() => setOpenVATCertificateModal(false)}
      />
      <Stack
        direction={"row"}
        sx={{ cursor: "pointer" }}
        onClick={() => setOpenVATCertificateModal(true)}
      >
        <Stack justifyContent={"center"} alignItems={"center"}>
          <Box
            component="img"
            sx={{
              width: "100%",
              height: "50px",
              objectFit: "contain",
              transition: "transform 0.3s ease",
              "&:hover": {
                transform: "scale(1.1)",
              },
            }}
            src={VATIcon}
            alt={"VATIcon"}
          />
        </Stack>
        <Stack
          sx={{ paddingX: "0.5rem" }}
          textAlign={"start"}
          direction={"column"}
          gap={"0.2rem"}
        >
          <Typography color="tertiary.tertiary3" variant="body2">
            {t("footer.VATNumberText")}
          </Typography>
          <Typography
            color="tertiary.tertiary2"
            sx={{ textDecoration: "underline" }}
            fontWeight={600}
            variant="body2"
          >
            {t("footer.VATNumber")}
          </Typography>
        </Stack>
      </Stack>
    </>
  );
};

export default VATSection;
