import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import ukFlag from "../../../../assets/images/flags/ukFlag.jpg";
import saudiFlag from "../../../../assets/images/flags/saudiFlag.png";
import { Stack } from "@mui/system";
import { Typography } from "@mui/material";

const NavbarLanguage = () => {
  //SECTION - general
  const { i18n } = useTranslation();

  //SECTION - useMemo
  const flagSrc = useMemo(
    () => (i18n.language === "en" ? saudiFlag : ukFlag),
    [i18n.language]
  );

  //SECTION - useCallback
  const toggleLanguage = useCallback(() => {
    i18n.changeLanguage(i18n.language === "en" ? "ar" : "en");
  }, [i18n]);
  return (
    <Stack
      sx={{
        cursor: "pointer",
      }}
      direction={"row"}
      alignItems={"center"}
      gap={"0.25rem"}
      onClick={toggleLanguage}
    >
      <Typography variant="body2" fontWeight={600} color="neutral.shade1">
        {i18n.language === "en" ? "عربي" : "EN"}
      </Typography>
      <img
        src={flagSrc}
        alt="language-flag"
        style={{
          height: "20px",
          objectFit: "contain",
          borderRadius: "8px",
        }}
        loading="lazy"
      />
    </Stack>
  );
};

export default NavbarLanguage;
