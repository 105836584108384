import i18n from "../../i18n";

export const formulateWatchTowerError = (response) => {
  let messageContent = "";
  if (response?.context) {
    messageContent += response.context + "\n";
  }
  if (response.errors) {
    if (Array.isArray(response.errors)) {
      response.errors.forEach((errorObj) => {
        if (response.errors.length === 1) {
          messageContent += response.errors[0];
        } else {
          Object.entries(errorObj).forEach(([errorType, errors]) => {
            errors.forEach((error) => {
              messageContent += "\n" + errorType + ": " + error;
            });
          });
        }
      });
    } else {
      Object.keys(response.errors).forEach((errorType) => {
        if (errorType === "context") {
          messageContent += "\n" + response.errors[errorType];
        }
        if (errorType !== "errorsList" && errorType !== "context") {
          Object.keys(response.errors).forEach((errorType) => {
            response.errors[errorType].forEach((error) => {
              messageContent += "\n" + errorType + ": " + error;
            });
          });
        }
      });
    }
  }

  if (response.detail) {
    messageContent += response.detail + "\n";
  }
  if (response.message) {
    messageContent += response.message + "\n";
  }
  if (messageContent === "") {
    messageContent = i18n.t("errorCodes.generalError");
  }
  return messageContent;
};
