import React, { useState, useCallback } from "react";
import { IconButton, Drawer, Divider, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import i18n from "i18next";
import { Stack } from "@mui/system";
import useGenerateNavbarItems from "../hooks/useGenerateNavbarItems";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logOut } from "../../../../app/features/auth/auth";
import { notifyWithError } from "../../../../app/features/error/error";
import { useTranslation } from "react-i18next";

const NavbarDrawer = () => {
  //SECTION - general
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  //SECTION - useState
  const [drawerOpen, setDrawerOpen] = useState(false);

  //SECTION - custom hooks
  const { navbarItems, handleNavigation } = useGenerateNavbarItems();

  //SECTION - functions
  const handleLogout = useCallback(() => {
    dispatch(logOut()).then((payload) => {
      if (payload.type === "auth/logOut/fulfilled") {
        navigate("/login");
      } else {
        dispatch(
          notifyWithError({
            message: t("errorCodes.logOutError"),
            type: "error",
          })
        );
      }
    });
  }, [dispatch, navigate, t]);

  const handleNavigate = useCallback(
    (item) => (item.id === "logout" ? handleLogout() : handleNavigation(item)),
    [handleLogout, handleNavigation]
  );

  const toggleDrawer = useCallback(() => {
    setDrawerOpen((prev) => !prev);
  }, []);

  return (
    <>
      <IconButton
        onClick={toggleDrawer}
        sx={{
          display: "flex",
          padding: "0.5rem",
          color: "primary.main",
        }}
        edge="start"
        aria-label="open drawer"
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        open={drawerOpen}
        onClose={toggleDrawer}
        anchor={i18n.dir() === "rtl" ? "right" : "left"}
        PaperProps={{
          style: {
            left: i18n.dir() === "rtl" ? "unset" : 0,
            right: i18n.dir() === "rtl" ? 0 : "unset",
            borderTopLeftRadius: i18n.dir() === "rtl" ? "15px" : "auto",
            borderBottomLeftRadius: i18n.dir() === "rtl" ? "15px" : "auto",
            borderTopRightRadius: i18n.dir() === "ltr" ? "15px" : "auto",
            borderBottomRightRadius: i18n.dir() === "ltr" ? "15px" : "auto",
          },
        }}
      >
        <Stack direction="column" gap="0.25rem" padding="1rem" width="250px">
          {navbarItems.map((item) => (
            <React.Fragment key={item.id}>
              {item.id === "logout" && <Divider sx={{ marginY: "0.5rem" }} />}
              <Stack
                direction={"row"}
                alignItems="center"
                textAlign={"center"}
                gap={"0.3rem"}
                sx={{
                  cursor: "pointer",
                  paddingY: "0.3rem",
                  paddingX: "0.75rem",
                  color:
                    item.id === "logout"
                      ? "error.main"
                      : item.selected
                      ? "primary.main"
                      : "neutral.shade3",
                  "&:hover": {
                    color: item.id === "logout" ? "error.main" : "primary.main",
                    backgroundColor:
                      item.id !== "logout" ? "primary.light" : "auto",
                  },
                  backgroundColor: item.selected ? "primary.light" : "auto",
                  borderRadius: "8px",
                }}
                onClick={() => handleNavigate(item)}
              >
                <Stack marginTop={"0.25rem"}>{item.icon}</Stack>
                <Typography
                  variant="body1"
                  fontWeight={item.selected ? 600 : 400}
                >
                  {t(item.label)}
                </Typography>
              </Stack>
            </React.Fragment>
          ))}
        </Stack>
      </Drawer>
    </>
  );
};

export default NavbarDrawer;
