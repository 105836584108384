import { Stack } from "@mui/system";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import { Typography } from "@mui/material";
import useGenerateNavbarItems from "../hooks/useGenerateNavbarItems";

//SECTION - general
const NavbarItems = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  //SECTION - useMemo
  const { navbarItems, handleNavigation } = useGenerateNavbarItems();

  //SECTION - functions
  const handleNavigate = useCallback(
    (item) => handleNavigation(item),
    [handleNavigation]
  );

  return (
    <Stack
      direction={"row"}
      gap={{ xs: "1rem", lg: "2rem" }}
      alignItems={"center"}
    >
      {navbarItems
        .filter((item) => item.showOnDesktop)
        .map((item) => (
          <Stack
            key={item.label}
            sx={{
              paddingY: "0.5rem",
              borderBottom: item.selected
                ? `2px solid ${theme.palette.primary.main}`
                : "none",
              color: item.selected ? "primary.main" : "neutral.shade3",
              cursor: "pointer",
              "&:hover": {
                color: "primary.main",
              },
            }}
            onClick={() => handleNavigate(item)}
          >
            <Typography variant="body1" fontWeight={item.selected ? 600 : 400}>
              {t(item.label)}
            </Typography>
          </Stack>
        ))}
    </Stack>
  );
};

export default NavbarItems;
