import React from "react";
import { Stack } from "@mui/material";

const Loader = ({ color = "#FA9940" }) => {
  return (
    <Stack alignItems="center" justifyContent="center">
      <div
        className="loader"
        style={{
          "--loader-color": color, // Dynamic color
          width: "45px",
          aspectRatio: "2",
          background: `
            no-repeat radial-gradient(circle closest-side, var(--loader-color) 90%, transparent) 0% 50%,
            no-repeat radial-gradient(circle closest-side, var(--loader-color) 90%, transparent) 50% 50%,
            no-repeat radial-gradient(circle closest-side, var(--loader-color) 90%, transparent) 100% 50%`,
          backgroundSize: "calc(100% / 3) 50%",
          animation: "l3 1s infinite linear",
        }}
      ></div>

      {/* Keyframes inside a <style> tag */}
      <style>
        {`
          @keyframes l3 {
            20% { background-position: 0% 0%, 50% 50%, 100% 50%; }
            40% { background-position: 0% 100%, 50% 0%, 100% 50%; }
            60% { background-position: 0% 50%, 50% 100%, 100% 0%; }
            80% { background-position: 0% 50%, 50% 50%, 100% 100%; }
          }
        `}
      </style>
    </Stack>
  );
};

export default Loader;
