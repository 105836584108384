//SECTION - GENERAL
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

//SECTION - REDUX
import { Provider } from "react-redux";
import { store } from "./app/store";

//SECTION - I18N
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));

//SECTION - remove logs on production
if (process.env.REACT_APP_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
  console.warn = () => {};
}

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <App />
      </Provider>
    </I18nextProvider>
  </React.StrictMode>
);
