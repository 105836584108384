import React, { useCallback, useMemo } from "react";
import autboiaLogo from "../../assets/images/logos/autobia.png";
import autboiaLogoAr from "../../assets/images/logos/arlogo.png";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

const AutobiaLogo = ({ isLarge, sizes }) => {
  //SECTION - general
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  //SECTION - useMemo
  const logoSrc = useMemo(
    () => (i18n.language === "ar" ? autboiaLogoAr : autboiaLogo),
    [i18n.language]
  );

  //SECTION - useCallback
  const handleLogoClick = useCallback(() => {
    if (location.pathname !== "/login") navigate("/home");
  }, [navigate, location.pathname]);

  return (
    <img
      onClick={handleLogoClick}
      src={logoSrc}
      alt="autobia-logo"
      style={{
        height:
          i18n.language === "ar"
            ? isLarge
              ? sizes?.ar?.lg || "65px"
              : sizes?.ar?.sm || "52px"
            : isLarge
            ? sizes?.en?.lg || "55px"
            : sizes?.en?.sm || "42px",
        objectFit: "contain",
        cursor: "pointer",
      }}
      loading="lazy"
    />
  );
};

export default AutobiaLogo;
