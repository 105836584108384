import { useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import { createTheme } from "@mui/system";
import baseTheme from "../materialUI/Theme";
import { updateUserLang } from "../../app/features/auth/auth";
import { useDispatch } from "react-redux";
import { validateWatchtowerToken } from "../api/Authentication";

const useThemeSetup = () => {
  //SECTION - language setup
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    document.body.dir = i18n.dir();
    document.documentElement.setAttribute("dir", i18n.dir());
    localStorage.setItem("i18nextLng", i18n.dir() === "rtl" ? "ar" : "en");
    localStorage.setItem("lng", i18n.language);
    if (validateWatchtowerToken()) dispatch(updateUserLang());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  //SECTION - theme setup
  const cache = useMemo(() => {
    return createCache({
      key: i18n.language === "ar" ? "muirtl" : "mui",
      stylisPlugins: i18n.language === "ar" ? [prefixer, rtlPlugin] : [],
    });
  }, [i18n.language]);

  const theme = useMemo(() => {
    return createTheme({
      ...baseTheme,
      direction: i18n.language === "ar" ? "rtl" : "ltr",
    });
  }, [i18n.language]);
  return { theme, cache };
};

export default useThemeSetup;
