import { Stack, useMediaQuery } from "@mui/system";
import React from "react";
import googleTransparent from "../../assets/images/logos/googleTransparent.svg";
import appleTransparent from "../../assets/images/logos/appleTransparent.svg";
import {
  handleAppStoreClick,
  handleGooglePlayClick,
} from "../../utils/Helpers/general";
import { useTheme } from "@emotion/react";

const DownloadAppIcons = ({ height = { small: "26px", large: "33px" } }) => {
  //SECTION - general
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("semiSm"));

  //SECTION - styles
  const iconStyle = {
    paddingY: "0.5rem",
    paddingX: "1rem",
    backgroundColor: "neutral.shade7",
    borderRadius: "12px",
    cursor: "pointer",
    transition: "transform 0.3s ease",
    "&:hover": {
      transform: "scale(1.1)",
    },
  };

  const imgStyle = {
    height: `calc(${isSmallScreen ? height.small : height.large} + 1vw)`,
    width: "100%",
    maxHeight: "35px",
    objectFit: "contain",
  };

  return (
    <Stack direction="row" alignItems="center" gap="1rem">
      <Stack
        sx={iconStyle}
        direction="row"
        onClick={handleAppStoreClick}
        flex={1}
        width="50%"
      >
        <img src={appleTransparent} alt="download-app-apple" style={imgStyle} />
      </Stack>
      <Stack
        sx={iconStyle}
        direction="row"
        onClick={handleGooglePlayClick}
        flex={1}
        width="50%"
      >
        <img
          src={googleTransparent}
          alt="download-app-google"
          style={imgStyle}
        />
      </Stack>
    </Stack>
  );
};

export default DownloadAppIcons;
