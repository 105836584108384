import * as React from "react";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { BiSupport, BiLogoTelegram } from "react-icons/bi";
import { IoCall, IoCloseOutline } from "react-icons/io5";
import { RiWhatsappFill } from "react-icons/ri";

const SpeedDialBtn = () => {
  const actions = React.useMemo(
    () => [
      {
        key: "whatsapp",
        icon: <RiWhatsappFill color="#25D366" fontSize={"1.5rem"} />,
        tooltipTitle: "Whatsapp",
        href: "https://wa.me/+966920031140",
      },
      {
        key: "telegram",
        icon: <BiLogoTelegram color="#2AABEE" fontSize={"1.5rem"} />,
        tooltipTitle: "Telegram",
        href: "https://t.me/autobiabot",
      },
      {
        key: "call",
        icon: <IoCall color="red" fontSize={"1.5rem"} />,
        tooltipTitle: "Call us",
        href: "tel:+966920031140",
      },
    ],
    []
  );

  return (
    <SpeedDial
      ariaLabel="SpeedDial openIcon example"
      sx={{ position: "fixed", bottom: 16, left: 16 }}
      icon={
        <SpeedDialIcon
          icon={<BiSupport fontSize={"1.5rem"} />}
          openIcon={<IoCloseOutline fontSize={"1.5rem"} />}
        />
      }
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.key}
          icon={action.icon}
          tooltipTitle={action.tooltipTitle}
          href={action.href}
        />
      ))}
    </SpeedDial>
  );
};

export default React.memo(SpeedDialBtn);
