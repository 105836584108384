import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import i18n from "i18next";
import IsEmpty from "../../../utils/validation/IsEmpty";
import { watchTowerInstance } from "../../../utils/api/axiosConfig";
import { notifyWithError } from "../error/error";
import { formulateWatchTowerError } from "../../../utils/api/formulateWatchTowerError";

const initialState = {
  offers: null,
  selectedOffer: null,
  selectedOfferId: null,
  advertisements: null,
  offersStatus: "idle",
  specificOfferStatus: "idle",
  fetchAdvertisement: "idle",
};

// export const fetchSpecificOffer = createAsyncThunk(
//   "cart/fetchSpecificOffer",
//   async (offerId) => {
//     try {
//       const response = await SingletonClient.getSpecificOffer(offerId);

//       return response;
//     } catch (error) {
//       throw Error("Failed to fetch specific offer from the API");
//     }
//   }
// );

export const fetchSpecificOffer = createAsyncThunk(
  "offer/fetchSpecificOffer",
  async (offerId, thunkAPI) => {
    const response = await watchTowerInstance.get(`platform-based/${offerId}/`);
    if (response.status < 200 || response.status >= 300) {
      thunkAPI.dispatch(
        notifyWithError({
          message: formulateWatchTowerError(response.data),
          type: "error",
        })
      );
      return thunkAPI.rejectWithValue(formulateWatchTowerError(response.data));
    } else {
      return response.data;
    }
  }
);

// export const fetchOffers = createAsyncThunk("cart/fetchOffers", async () => {
//   try {
//     const response = await SingletonClient.getOffers();

//     return response;
//   } catch (error) {
//     throw Error("Failed to fetch offers from the API");
//   }
// });

export const fetchOffers = createAsyncThunk(
  "offer/fetchOffers",
  async (thunkAPI) => {
    const response = await watchTowerInstance.get(`platform-based/`);
    if (response.status < 200 || response.status >= 300) {
      thunkAPI.dispatch(
        notifyWithError({
          message: formulateWatchTowerError(response.data),
          type: "error",
        })
      );
      return thunkAPI.rejectWithValue(formulateWatchTowerError(response.data));
    } else {
      return response.data;
    }
  }
);
// export const fetchAdvertisement = createAsyncThunk(
//   "cart/fetchAdvertisement",
//   async () => {
//     try {
//       const response = await SingletonClient.getAdvertisments();
//       return response.data;
//     } catch (error) {
//       throw Error("Failed to fetch offers from the API");
//     }
//   }
// );
export const fetchAdvertisement = createAsyncThunk(
  "offer/fetchAdvertisement",
  async (thunkAPI) => {
    const response = await watchTowerInstance.get(`advertisement`);
    if (response.status < 200 || response.status >= 300) {
      thunkAPI.dispatch(
        notifyWithError({
          message: formulateWatchTowerError(response.data),
          type: "error",
        })
      );
      return thunkAPI.rejectWithValue(formulateWatchTowerError(response.data));
    } else {
      return response.data;
    }
  }
);
export const offerSlice = createSlice({
  name: "offer",
  initialState,
  reducers: {
    selectOffer: (state, action) => {
      state.selectedOfferId = action.payload;
    },
    resetOffers: (state, action) => initialState,
  },
  extraReducers: (builder) => {
    //SECTION - fetchOffers
    builder.addCase(fetchOffers.fulfilled, (state, action) => {
      state.offersStatus = "succeeded";
      state.offers = action.payload;
    });
    builder.addCase(fetchOffers.pending, (state, action) => {
      state.offersStatus = "loading";
    });
    builder.addCase(fetchOffers.rejected, (state, action) => {
      state.offersStatus = "failed";
      state.error = action.error.message;
    });
    //SECTION - fetchSpecificOffer
    builder.addCase(fetchSpecificOffer.fulfilled, (state, action) => {
      state.specificOfferStatus = "succeeded";
      state.selectedOffer = action.payload;
      state.selectedOfferId = action.payload.id;
    });
    builder.addCase(fetchSpecificOffer.pending, (state, action) => {
      state.specificOfferStatus = "loading";
    });
    builder.addCase(fetchSpecificOffer.rejected, (state, action) => {
      state.specificOfferStatus = "failed";
      state.error = action.error.message;
    });
    builder.addCase(fetchAdvertisement.fulfilled, (state, action) => {
      state.fetchAdvertisement = "succeeded";
      let adverts = [];
      if (!IsEmpty(action.payload)) {
        for (const item of action.payload) {
          if (
            item.deactivate_by === null &&
            moment(item.expiration_at) > moment() &&
            item.hasOwnProperty("filer_list")
          ) {
            for (const filee of item.filer_list) {
              if (filee.platform?.name_en === "Web Store") {
                const newobject = {
                  id: item.id,
                  title_en: item.title_en,
                  title_ar: item.title_ar,
                  description_en: item.description_en,
                  description_ar: item.description_ar,
                  button_en: item.button_en,
                  button_ar: item.button_ar,
                  expiration_at: item.expiration_at,
                  action: item.action,
                  filer: {
                    file:
                      i18n.dir() === "rtl"
                        ? filee.ar_filer.file
                        : filee.en_filer.file,
                  },
                  created_at: item.created_at,
                };
                adverts.push(newobject);
              }
            }
          }
        }
      }
      state.advertisements = adverts;
    });
    builder.addCase(fetchAdvertisement.pending, (state, action) => {
      state.fetchAdvertisement = "loading";
    });
    builder.addCase(fetchAdvertisement.rejected, (state, action) => {
      state.fetchAdvertisement = "failed";
      state.error = action.error.message;
    });
  },
});

export const { selectOffer, resetOffers } = offerSlice.actions;

export default offerSlice.reducer;

export const selectOffers = (state) => state.offerReducer.offers;
export const currentlySelectedOffer = (state) =>
  state.offerReducer.selectedOffer;
export const currentlySelectedOfferId = (state) =>
  state.offerReducer.selectedOfferId;
export const getOffersStatus = (state) => state.offerReducer.offersStatus;
export const getSpecificOfferStatus = (state) =>
  state.offerReducer.specificOfferStatus;
export const getAdvertisement = (state) => state.offerReducer.advertisements;
export const getFetchAdvertisement = (state) =>
  state.offerReducer.fetchAdvertisement;
