import { configureStore } from "@reduxjs/toolkit";
import cartReducer from "./features/cart/cart";
import orderReducer from "./features/order/order";
import brandReducer from "./features/brand/brand";
import loggerMiddleware from "./middleware/logger";
import errorReducer from "./features/error/error";
import addressReducer from "./features/address/address";
import offerReducer from "./features/offer/offer";
import walletReducer from "./features/wallet/wallet";
import rewardsReducer from "./features/rewards/rewards";
import authReducer from "./features/auth/auth";
import orderTrackingReducer from "./features/orderTracking/orderTracking";
import commonReducer from "./features/common/common";
import bannerReducer from "./features/banner/banner";
import interactiveUserInterfaceReducer from "./features/interactiveUserInterface/interactiveUserInterface";
import registerInterestsReducer from "./features/registerInterests/registerInterests";
import creditReducer from "./features/creditService/creditService";

export const store = configureStore({
  reducer: {
    cartReducer,
    orderReducer,
    brandReducer,
    errorReducer,
    addressReducer,
    offerReducer,
    walletReducer,
    rewardsReducer,
    authReducer,
    orderTrackingReducer,
    commonReducer,
    bannerReducer,
    interactiveUserInterfaceReducer,
    registerInterestsReducer,
    creditReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(loggerMiddleware),
});
