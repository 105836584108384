import { createTheme } from "@mui/material";
import { responsiveFontSizes } from "@mui/material/styles";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      semiSm: 425,
      sm: 600,
      semiMd: 700,
      md: 900,
      semiLg: 1024,
      lg: 1200,
      xl: 1536,
      xxl: 1750,
      xxxl: 2000,
    },
  },
  palette: {
    neutral: {
      shade1: "#292929",
      shade2: "#4B4B4B",
      shade3: "#666666",
      shade7: "#F1F1F1",
    },
    primary: {
      //Autboia Orange
      light: "#FFF4EA",
      l700: "#FEF2E9",
      main: "#FA9940",
      dark: "#E27E30",
      medium: "#FDE5D2",
      contrastText: "#fff",
    },
    secondary: {
      //Autboia Blue
      light: "#E5E8FF",
      main: "#4A60F4",
      dark: "#495FA3",
      lightDisable: "#C1D8F0",
      contrastText: "#fff",
      s500: "#7C8CF9",
      s400: "#e9ebf9",
    },
    tertiary: {
      //Autboia Black
      light: "#EAECF0",
      main: "#000000",
      dark: "#333232",
      contrastText: "#fff",
      tertiary2: "#111827",
      tertiary3: "#2B3449",
    },
    success: {
      //Autboia Green
      light: "#ECFDF3",
      main: "#12B76A",
      dark: "#039855",
      contrastText: "#fff",
      s25: "#F6FEF9",
      s50: "#ECFDF3",
      s200: "#A6F4C5",
      s300: "#6CE9A6",
      s400: "#32D583",
      s500: "#12B76A",
      s600: "#039855",
      s900: "#054F31",
      s800: "#05603A",
    },
    error: {
      light: "#FEF3F2",
      main: "#F04438",
      dark: "#d32f2f",
      contrastText: "#fff",
      e400: "#F97066",
      e100: "#FEE4E2",
      e50: "#FEF3F2",
    },
    gray: {
      g25: "#F9FAFF",
      g25second: "#FCFCFD",
      g50: "#F9FAFB",
      g75: "#F4F5FA",
      g100: "#F2F4F7",
      g200: "#EAECF0",
      g400: "#98A2B3",
      g500: "#667085",
      g600: "#475467",
      g700: "#344054",
      background: "#FAFAFA",
      g60: "#EBEBEB",
    },
    yellow: {
      light: "#FFFBEB",
      main: "#FACC15",
      dark: "#EAB308",
    },
    teal: {
      veryLight: "#b2d8d8",
      light: "#66b2b2",
      main: "#008080",
      dark: "	#006666",
      veryDark: "#004c4c",
      grayTeal: "#77909C",
    },
    white: "#fff",
    background: {
      default: "#fff",
      paper: "#fff",
      lightGray: "#f5f6fc",
    },
    companies: {
      tabby: "#3BFFA9",
      tamara: "#F5AE84",
    },
  },
  typography: {
    // Add "Almarai" as the first option
    fontFamily: "Almarai, Arial",
    regular: 400, // Default font weight (regular)
    medium: 500, // Medium font weight
    bold: 700, // Bold font weight
  },
  shadows: [
    "none",
    "0px 2px 1px -1px rgba(173,174,176,0.2),0px 1px 1px 0px rgba(173,174,176,0.14),0px 1px 3px 0px rgba(173,174,176,0.12)",
    "0px 3px 1px -2px rgba(173,174,176,0.2),0px 2px 2px 0px rgba(173,174,176,0.14),0px 1px 5px 0px rgba(173,174,176,0.12)",
    "0px 3px 3px -2px rgba(173,174,176,0.2),0px 3px 4px 0px rgba(173,174,176,0.14),0px 1px 8px 0px rgba(173,174,176,0.12)",
    "0px 2px 4px -1px rgba(173,174,176,0.2),0px 4px 5px 0px rgba(173,174,176,0.14),0px 1px 10px 0px rgba(173,174,176,0.12)",
    "0px 3px 5px -1px rgba(173,174,176,0.2),0px 5px 8px 0px rgba(173,174,176,0.14),0px 1px 14px 0px rgba(173,174,176,0.12)",
    "0px 3px 5px -1px rgba(173,174,176,0.2),0px 6px 10px 0px rgba(173,174,176,0.14),0px 1px 18px 0px rgba(173,174,176,0.12)",
    "0px 4px 5px -2px rgba(173,174,176,0.2),0px 7px 10px 1px rgba(173,174,176,0.14),0px 2px 16px 1px rgba(173,174,176,0.12)",
    "0px 5px 5px -3px rgba(173,174,176,0.2),0px 8px 10px 1px rgba(173,174,176,0.14),0px 3px 14px 2px rgba(173,174,176,0.12)",
    "0px 5px 6px -3px rgba(173,174,176,0.2),0px 9px 12px 1px rgba(173,174,176,0.14),0px 3px 16px 2px rgba(173,174,176,0.12)",
    "0px 6px 6px -3px rgba(173,174,176,0.2),0px 10px 14px 1px rgba(173,174,176,0.14),0px 4px 18px 3px rgba(173,174,176,0.12)",
    "0px 6px 7px -4px rgba(173,174,176,0.2),0px 11px 15px 1px rgba(173,174,176,0.14),0px 4px 20px 3px rgba(173,174,176,0.12)",
    "0px 7px 8px -4px rgba(173,174,176,0.2),0px 12px 17px 2px rgba(173,174,176,0.14),0px 5px 22px 4px rgba(173,174,176,0.12)",
    "0px 7px 8px -4px rgba(173,174,176,0.2),0px 13px 19px 2px rgba(173,174,176,0.14),0px 5px 24px 4px rgba(173,174,176,0.12)",
    "0px 7px 9px -4px rgba(173,174,176,0.2),0px 14px 21px 2px rgba(173,174,176,0.14),0px 5px 26px 4px rgba(173,174,176,0.12)",
    "0px 8px 9px -5px rgba(173,174,176,0.2),0px 15px 22px 2px rgba(173,174,176,0.14),0px 6px 28px 5px rgba(173,174,176,0.12)",
    "0px 8px 10px -5px rgba(173,174,176,0.2),0px 16px 24px 2px rgba(173,174,176,0.14),0px 6px 30px 5px rgba(173,174,176,0.12)",
    "0px 8px 11px -5px rgba(173,174,176,0.2),0px 17px 26px 2px rgba(173,174,176,0.14),0px 6px 32px 5px rgba(173,174,176,0.12)",
    "0px 9px 11px -5px rgba(173,174,176,0.2),0px 18px 28px 2px rgba(173,174,176,0.14),0px 7px 34px 6px rgba(173,174,176,0.12)",
    "0px 9px 12px -6px rgba(173,174,176,0.2),0px 19px 29px 2px rgba(173,174,176,0.14),0px 7px 36px 6px rgba(173,174,176,0.12)",
    "0px 10px 13px -6px rgba(173,174,176,0.2),0px 20px 31px 3px rgba(173,174,176,0.14),0px 8px 38px 7px rgba(173,174,176,0.12)",
    "0px 10px 13px -6px rgba(173,174,176,0.2),0px 21px 33px 3px rgba(173,174,176,0.14),0px 8px 40px 7px rgba(173,174,176,0.12)",
    "0px 10px 14px -6px rgba(173,174,176,0.2),0px 22px 35px 3px rgba(173,174,176,0.14),0px 8px 42px 7px rgba(173,174,176,0.12)",
    "0px 11px 14px -7px rgba(173,174,176,0.2),0px 23px 36px 3px rgba(173,174,176,0.14),0px 9px 44px 8px rgba(173,174,176,0.12)",
    "0px 11px 15px -7px rgba(173,174,176,0.2),0px 24px 38px 3px rgba(173,174,176,0.14),0px 9px 46px 8px rgba(173,174,176,0.12)",
  ],
});

export default responsiveFontSizes(theme);
