import { useState } from "react";
import ReactGA from "react-ga4";

const GA4_ID = process.env.REACT_APP_GA4_ID;

export const useGoogleAnalytics = () => {
  const [isGAInitialized, setIsGAInitialized] = useState(false);

  const initGA4 = () => {
    if (!isGAInitialized) {
      ReactGA.initialize(GA4_ID, {
        testMode: process.env.REACT_APP_ENV !== "production",
      });
      setIsGAInitialized(true);
    }
  };

  const logUserProperties = (userId, userAttributes) => {
    try {
      ReactGA.set({ userId, ...userAttributes });
    } catch (error) {
      console.error("Error logging user properties:", error);
    }
  };

  const logPageView = (title, additionalData = {}) => {
    try {
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname,
        title,
        ...additionalData,
      });
    } catch (error) {
      console.error("Error logging page view:", error);
    }
  };

  const logCartEvent = (eventName, cartItems) => {
    try {
      const cartData = {
        cart_value: cartItems.reduce(
          (acc, item) =>
            acc +
            item?.items_detail?.selling_price * item?.items_detail?.quantity,
          0
        ),
        items: cartItems.map((item) => ({
          item_id: item?.id,
          item_name: item?.item?.part_number,
          item_brand: item?.brand?.name_en,
          item_category: item?.item?.manufacturer?.name_en,
          price: parseFloat(item?.items_detail?.selling_price) || 0,
          quantity: item?.items_detail?.quantity || 0,
          coupon: item?.offer_id,
        })),
      };

      ReactGA.gtag("event", eventName, {
        currency: "SAR",
        value: cartData.cart_value,
        items: cartData.items,
      });
    } catch (error) {
      console.error(`Error logging ${eventName}:`, error);
    }
  };

  const logAddToCart = (cartItems) => logCartEvent("add_to_cart", cartItems);
  const logRemoveFromCart = (cartItems) =>
    logCartEvent("remove_from_cart", cartItems);

  const logPurchase = (purchaseData) => {
    try {
      if (!purchaseData.transaction_id || !purchaseData.items?.length) {
        console.error("Invalid purchase data:", purchaseData);
        return;
      }

      ReactGA.gtag("event", "purchase", purchaseData);
    } catch (error) {
      console.error("Error logging purchase:", error);
    }
  };

  return {
    initGA4,
    logUserProperties,
    logPageView,
    logAddToCart,
    logRemoveFromCart,
    logPurchase,
  };
};
