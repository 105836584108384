import React, { useCallback } from "react";
import { LuShoppingCart } from "react-icons/lu";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import getTotalItemsQuantity from "../../../../utils/Helpers/getTotalItemsQuantity";
import NavIcon from "./NavIcon";

const NavbarCart = () => {
  //SECTION - general
  const navigate = useNavigate();
  const location = useLocation();

  //SECTION - useSelector
  const itemsInBasket = useSelector((state) => state.cartReducer.items);

  //SECTION - functions
  const totalQuantity = getTotalItemsQuantity(itemsInBasket);
  const navigateCart = useCallback(() => navigate("/cart"), [navigate]);

  return (
    <NavIcon
      navigateTo={navigateCart}
      badgeNumber={totalQuantity}
      icon={<LuShoppingCart fontSize={"1.25rem"} />}
      selected={location.pathname === "/cart"}
    />
  );
};

export default NavbarCart;
