import { AppBar, Stack, useMediaQuery } from "@mui/material";
import NavbarItems from "./components/NavbarItems";
import NavbarCart from "./components/NavbarCart";
import NavbarProfile from "./components/NavbarProfile";
import NavbarLanguage from "./components/NavbarLanguage";
import NavbarDrawer from "./components/NavbarDrawer";
import AutobiaLogo from "../../common/AutobiaLogo";
import React from "react";

const Navbar = () => {
  //SECTION - general
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: "#fff",
        height: "64px",
        borderBottomLeftRadius: "20px",
        borderBottomRightRadius: "20px",
        zIndex: 1000,
        boxShadow: `
          0px 4px 8px rgba(255, 210, 168, 0.1),
          0px 14px 14px rgba(255, 210, 168, 0.09),
          0px 32px 19px rgba(255, 210, 168, 0.05),
          0px 56px 23px rgba(255, 210, 168, 0.01),
          0px 88px 25px rgba(255, 210, 168, 0)`,
        justifyContent: "space-between",
        flexDirection: "row",
        color: "black",
        paddingX: { xs: "1rem", sm: "2rem", lg: "4rem" },
        alignItems: "center",
      }}
    >
      <Stack
        direction={"row"}
        alignItems={"center"}
        sx={{ cursor: "pointer" }}
        gap={"0.5rem"}
      >
        {/* drawer */}
        {!isLargeScreen && <NavbarDrawer />}
        {/* autobia logo */}
        <AutobiaLogo
          isLarge={isLargeScreen}
          sizes={{
            ar: { sm: "35px", lg: "40px" },
            en: {
              sm: "35px",
              lg: "30px",
            },
          }}
        />
      </Stack>
      {/* navbar items */}
      {isLargeScreen && <NavbarItems />}
      {/* language/cart/profile section */}
      <Stack direction={"row"} alignItems={"center"} gap={"0.75rem"}>
        <NavbarLanguage />
        <NavbarCart />
        {isLargeScreen && <NavbarProfile />}
      </Stack>
    </AppBar>
  );
};

export default React.memo(Navbar);
