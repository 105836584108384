//SECTION - GENERAL
import React from "react";
import { Box } from "@mui/material";

//SECTION - COMPONENTS
import Footer from "../Footer/Footer";
import OrdersNotification from "../../common/OrdersNotification";
import SpeedDialBtn from "../../../views/SpeedDial/SpeedDialBtn";
import PromotionBanner from "../../../views/Wallet/PromotionBanner";
import Navbar from "../Navbar/Navbar";

//SECTION - SETUP
import { CacheProvider, ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import useThemeSetup from "../../../utils/hooks/useThemeSetup";
import { validateTowerToken } from "../../../utils/api/Authentication";
import CustomizedSnackbar from "../../common/Snackbar";

const Wrapper = ({ component }) => {
  const { theme, cache } = useThemeSetup();
  const userLoggedIn = validateTowerToken();

  return (
    <>
      <CacheProvider value={cache}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <CustomizedSnackbar />
          {userLoggedIn && (
            <>
              <PromotionBanner />
              <OrdersNotification /> <SpeedDialBtn /> <Navbar />
            </>
          )}
          <Box width={"100%"} flexGrow={1}>
            {component}
          </Box>
          {userLoggedIn && <Footer />}
        </ThemeProvider>
      </CacheProvider>
    </>
  );
};

export default Wrapper;
