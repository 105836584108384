import React, { useMemo } from "react";
import { IoLogoInstagram, IoLogoWhatsapp } from "react-icons/io5";
import { FaLinkedinIn } from "react-icons/fa";
import { SlSocialTwitter } from "react-icons/sl";
import { PiTelegramLogo } from "react-icons/pi";
import { FiPhone } from "react-icons/fi";

const useGenerateNavigationItems = () => {
  const navigationItems = useMemo(
    () => [
      {
        link: "https://www.linkedin.com/company/autobia/",
        icon: <FaLinkedinIn />,
      },
      {
        link: "https://www.instagram.com/autobia.sa/",
        icon: <IoLogoInstagram />,
      },
      {
        link: "https://wa.me/+966920031140",
        icon: <IoLogoWhatsapp />,
      },
      {
        link: "https://twitter.com/autobia_sa",
        icon: <SlSocialTwitter />,
      },
      {
        link: "https://t.me/autobiabot",
        icon: <PiTelegramLogo />,
      },
      {
        link: "tel:+966920031140",
        icon: <FiPhone />,
      },
    ],
    []
  );

  return navigationItems;
};

export default useGenerateNavigationItems;
