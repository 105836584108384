import { useCallback, useMemo } from "react";
import { BsFillBoxSeamFill } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const useGenerateNavbarItems = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const navigate = useNavigate();

  const handleNavigation = useCallback(
    (item) => {
      if (item.internal) {
        navigate(item.url);
      } else {
        window.open(item.url, "_blank");
      }
    },
    [navigate]
  );

  const canViewCreditService = useSelector(
    (state) => state.walletReducer.canViewCreditService
  );

  const navbarItems = useMemo(() => {
    const items = [
      {
        id: "home",
        label: "navbar.home",
        url: "/home",
        selected: pathname === "/home",
        internal: true,
        showOnDesktop: true,
        icon: (
          <i
            className="fi fi-rr-house-chimney-window"
            style={{ fontSize: "1.2rem" }}
          />
        ),
      },
      {
        id: "orders",
        label: "navbar.orders",
        url: "/orders",
        selected: pathname === "/orders",
        internal: true,
        showOnDesktop: true,
        icon: (
          <i className="fi fi-rr-box-open" style={{ fontSize: "1.2rem" }} />
        ),
      },
      {
        id: "offers",
        label: "navbar.offers",
        url: "/offers",
        selected: pathname === "/offers",
        internal: true,
        showOnDesktop: true,
        icon: (
          <i
            className="fi fi-rr-badge-percent"
            style={{ fontSize: "1.2rem" }}
          />
        ),
      },
      {
        id: "installmentService",
        label: "navbar.installmentService",
        url: "/installment-services",
        selected:
          pathname.includes("/wallet") || pathname === "/installment-services",
        internal: true,
        showOnDesktop: true,
        icon: (
          <i
            className="fi fi-rr-payroll-check"
            style={{ fontSize: "1.2rem" }}
          />
        ),
      },
      {
        id: "branches",
        label: "navbar.branches",
        url: "/branches",
        selected: pathname === "/branches",
        internal: true,
        showOnDesktop: true,
        icon: (
          <i className="fi fi-rr-building" style={{ fontSize: "1.2rem" }} />
        ),
      },
      ...(canViewCreditService
        ? [
            {
              id: "creditService",
              label: "navbar.creditService",
              url: "/credit-service",
              selected: pathname === "/credit-service",
              internal: true,
              showOnDesktop: true,
              icon: (
                <i
                  class="fi fi-rr-time-forward"
                  style={{ fontSize: "1.2rem" }}
                />
              ),
            },
          ]
        : []),
      {
        id: "usageInstructions",
        label: "navbar.usageInstructions",
        url: "https://deals-uploads.s3.eu-central-1.amazonaws.com/public/files/general/Web+Ordering+steps_02+(2)+(1).pdf",
        selected: false,
        internal: false,
        showOnDesktop: false,
        icon: (
          <i className="fi fi-rr-comment-info" style={{ fontSize: "1.2rem" }} />
        ),
      },
      {
        id: "payment",
        label: "navbar.paymentMethods",
        url: "/payment",
        selected: pathname === "/payment",
        internal: true,
        showOnDesktop: false,
        icon: (
          <i className="fi fi-rr-credit-card" style={{ fontSize: "1.25rem" }} />
        ),
      },
      {
        id: "logout",
        label: "navbar.logout",
        url: "/logout",
        selected: false,
        internal: true,
        showOnDesktop: false,
        icon: (
          <i className="fi fi-sr-sign-out-alt" style={{ fontSize: "1.2rem" }} />
        ),
      },
    ];

    return items;
  }, [pathname, canViewCreditService]);

  return { navbarItems, handleNavigation };
};

export default useGenerateNavbarItems;
