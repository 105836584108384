import moment from "moment";

export const formatDate = (date) => {
  return moment(date).format("DD/MM/YYYY");
};

export const formatDateTime = (date) => {
  return moment(date).format("DD/MM/YYYY hh:mm:ss");
};

export const formatDateShort = (date) => {
  return moment(date).format("DD/MM");
};

export const formatTimeShort = (date) => {
  return moment(date).format("HH:mm");
};

export function ccyFormat(num, max = 2) {
  const price = parseFloat(num);
  return `${price.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: max,
  })}`;
}

export function reduceFeesAmount(amount, commission_rate, refund_rate) {
  const finalAmount =
    amount -
    amount *
      (refund_rate != null ? commission_rate - refund_rate : commission_rate);
  return Number(finalAmount.toFixed(2));
}

export const convertArabicNumbersToEnglish = (str) => {
  const arabicDigits = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
  const englishDigits = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

  let englishStr = str;
  arabicDigits.forEach((digit, index) => {
    const regex = new RegExp(digit, "g");
    englishStr = englishStr.replace(regex, englishDigits[index]);
  });

  return englishStr;
};

export const handleGooglePlayClick = () => {
  window.open(
    "https://play.google.com/store/apps/details?id=com.autobia.autobia"
  );
};

export const handleAppStoreClick = () => {
  window.open("https://apps.apple.com/sa/app/id1661648763");
};
