import { Navigate, Outlet } from "react-router-dom";
import { validateTowerToken } from "../utils/api/Authentication";

const AuthProtected = ({ children }) => {
  const redirectPath = "/login";
  if (validateTowerToken()) {
    return children ? children : <Outlet />;
  }
  return <Navigate to={redirectPath} replace />;
};
export default AuthProtected;
