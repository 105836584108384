import { useTheme } from "@emotion/react";
import { Divider, Popover, Typography, Stack } from "@mui/material";
import React, { useState, useCallback } from "react";
import { IoPerson } from "react-icons/io5";
import useGenerateNavbarItems from "../hooks/useGenerateNavbarItems";
import { t } from "i18next";
import { logOut } from "../../../../app/features/auth/auth";
import { notifyWithError } from "../../../../app/features/error/error";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import NavIcon from "./NavIcon";

const NavbarProfile = () => {
  //SECTION - general
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  //SECTION - useState
  const [anchorEl, setAnchorEl] = useState(null);

  //SECTION - custom hooks
  const { navbarItems, handleNavigation } = useGenerateNavbarItems();

  //SECTION - functions
  const handlePersonClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleLogout = useCallback(() => {
    dispatch(logOut()).then((payload) => {
      if (payload.type === "auth/logOut/fulfilled") {
        navigate("/login");
      } else {
        dispatch(
          notifyWithError({
            message: t("errorCodes.logOutError"),
            type: "error",
          })
        );
      }
    });
  }, [dispatch, navigate]);

  const handleNavigate = useCallback(
    (item) => (item.id === "logout" ? handleLogout() : handleNavigation(item)),
    [handleLogout, handleNavigation]
  );

  const isPopoverOpen = Boolean(anchorEl);

  return (
    <>
      <NavIcon
        navigateTo={handlePersonClick}
        badgeNumber={null}
        icon={<IoPerson fontSize={"1.25rem"} />}
        selected={isPopoverOpen}
      />
      <Popover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        slotProps={{
          paper: {
            sx: { borderRadius: "15px" },
          },
        }}
      >
        <Stack direction="column" gap="0.8rem" padding="1rem" width="250px">
          {navbarItems
            .filter((item) => !item.showOnDesktop)
            .map((item) => (
              <React.Fragment key={item.id}>
                {item.id === "logout" && <Divider />}
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  gap={"0.5rem"}
                  textAlign={"center"}
                  sx={{
                    cursor: "pointer",
                    color:
                      item.id === "logout"
                        ? "error.main"
                        : item.selected
                        ? "primary.main"
                        : "neutral.shade3",
                    "&:hover": {
                      color:
                        item.id === "logout" ? "error.main" : "primary.main",
                    },
                  }}
                  onClick={() => handleNavigate(item)}
                >
                  <Stack marginTop={"0.25rem"}>{item.icon}</Stack>
                  <Typography
                    variant="body1"
                    fontWeight={item.selected ? 600 : 400}
                  >
                    {t(item.label)}
                  </Typography>
                </Stack>
              </React.Fragment>
            ))}
        </Stack>
      </Popover>
    </>
  );
};

export default NavbarProfile;
