import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { watchTowerInstance } from "../../../utils/api/axiosConfig";
import { notifyWithError } from "../error/error";
import { formulateWatchTowerError } from "../../../utils/api/formulateWatchTowerError";

const initialState = {
  creditServiceDashboard: null,
  getCreditServiceDashboardStatus: "idle",
};

export const getCreditServiceDashboard = createAsyncThunk(
  "credit/getCreditServiceDashboard",
  async (thunkAPI) => {
    const response = await watchTowerInstance.get(
      `company/credit-line-dashboard`
    );

    if (response.status < 200 || response.status >= 300) {
      thunkAPI.dispatch(
        notifyWithError({
          message: formulateWatchTowerError(response.data),
          type: "error",
        })
      );
      return thunkAPI.rejectWithValue(response.data);
    } else {
      return response.data;
    }
  }
);

export const creditSlice = createSlice({
  name: "credit",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCreditServiceDashboard.fulfilled, (state, action) => {
      state.getCreditServiceDashboardStatus = "succeeded";
      state.creditServiceDashboard = action.payload;
    });
    builder.addCase(getCreditServiceDashboard.pending, (state, action) => {
      state.getCreditServiceDashboardStatus = "loading";
    });
    builder.addCase(getCreditServiceDashboard.rejected, (state, action) => {
      state.getCreditServiceDashboardStatus = "failed";
    });
  },
});

export default creditSlice.reducer;
