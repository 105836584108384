import { useTheme } from "@emotion/react";
import { Badge } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";

const NavIcon = ({ navigateTo, badgeNumber, icon: Icon, selected }) => {
  //SECTION - general
  const theme = useTheme();

  return (
    <Stack
      sx={{
        height: "40px",
        width: "40px",
        border: `1px solid ${
          selected ? theme.palette.primary.main : theme.palette.primary.l700
        }`,
        borderRadius: "12px",
        color: "primary.main",
        cursor: "pointer",
        position: "relative",
      }}
      onClick={navigateTo}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Badge
        badgeContent={badgeNumber}
        color="error"
        sx={{
          "& .MuiBadge-badge": {
            left: -6,
            top: -9,
            border: `2px solid #fff`,
          },
        }}
      >
        {Icon}
      </Badge>
    </Stack>
  );
};

export default NavIcon;
