import { useTheme } from "@emotion/react";
import { Stack } from "@mui/system";
import React from "react";

const NavigationIcon = ({ icon: Icon, link }) => {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        width: "48px",
        height: "48px",
        backgroundColor: "#fff",
        borderRadius: "12px",
        cursor: "pointer",
        boxShadow:
          "0px -7px 10px 0px rgba(75, 75, 75, 0.01), 0px 7px 10px 0px rgba(75, 75, 75, 0.10)",
        transition: "transform 0.3s ease",
        "&:hover": {
          transform: "scale(1.1)",
        },
      }}
      justifyContent={"center"}
      alignItems={"center"}
      onClick={() => window.open(link, "_blank")}
    >
      {React.cloneElement(Icon, {
        fontSize: "1.5rem",
        color: theme.palette.primary.main,
      })}
    </Stack>
  );
};

export default NavigationIcon;
