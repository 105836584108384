import { Link } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { useTranslation } from "react-i18next";

const TermsAndPrivacyLinks = ({ color = "primary" }) => {
  const { i18n, t } = useTranslation();
  const baseUrl =
    process.env.REACT_APP_LANDING_PAGE_URL +
    (i18n.language === "ar" ? "" : "en/");

  const links = [
    {
      href: `${baseUrl}terms-and-conditions.html`,
      label: t("labels.termsAndConditions"),
    },
    {
      href: `${baseUrl}privacy-policy.html`,
      label: t("labels.privacyPolicy"),
    },
  ];

  return (
    <Stack direction="row" alignItems="center" gap="1rem">
      {links.map((link, index) => (
        <Link
          key={index}
          href={link.href}
          underline="always"
          target="_blank"
          rel="noopener noreferrer"
          color={color}
        >
          {link.label}
        </Link>
      ))}
    </Stack>
  );
};

export default TermsAndPrivacyLinks;
