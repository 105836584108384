import { jwtDecode } from "jwt-decode";

/**
 * Checks if a token expiration timestamp is still valid.
 * @param {number} exp - Expiration timestamp (in seconds).
 * @returns {boolean} - Returns true if the token is still valid, otherwise false.
 */
const validateTokenExpiry = (exp) => {
  return new Date(exp * 1000).getTime() > new Date().getTime();
};

/**
 * Validates a token stored in localStorage.
 * @param {string} tokenKey - The key of the token in localStorage.
 * @returns {boolean} - Returns true if the token is valid, otherwise false.
 */
export function validateToken(tokenKey) {
  const token = localStorage.getItem(tokenKey);
  if (!token) return false;

  try {
    const decoded = jwtDecode(token);
    return decoded?.exp && validateTokenExpiry(decoded.exp);
  } catch (error) {
    console.error(`Error decoding token (${tokenKey}):`, error);
    return false; // Return false if token decoding fails
  }
}

// Specific validation functions for Tower and Watchtower tokens
export const validateTowerToken = () => validateToken("accessToken");
export const validateWatchtowerToken = () =>
  validateToken("watchTowerAccessToken");

/**
 * Validates both Tower and Watchtower tokens.
 * @returns {boolean} - Returns true only if **both** tokens are valid.
 */
export const validateTowerAndWatchTowerTokens = () =>
  validateTowerToken() && validateWatchtowerToken();
